import React, { useCallback, useEffect, useState } from 'react';
import { POST } from 'api/index';
import { ChatResponseInterface, ParagraphInterface, UserInputInterface } from 'entities/interfaces';
import ChatComponent from 'ui/components/chat/ChatComponent';
import useAlert from 'hooks/AlertHook';
import { parseHTMLToJSON } from 'utils/urlDecoder';
import { fetchChatGPTResponse } from 'api/chatGPT';


const SkillsChatWrapper = ({ active, updateProgress }: any) => {
    const { setAlert } = useAlert();
    const token = window.localStorage.getItem("currentUserToken");
    const [nodesData, setNodesData] = useState<ParagraphInterface[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [calledNodes, setCalledNodes] = useState<number[]>([]);
    const [chapter, setChapter] = useState<{
        id: string
        title: string
        num: number,
        isBookmarked: boolean
    }>();
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [typingAllowed, setTypingAllowed] = useState(false);
    const [processedChatMessages, setProcessedChatMessages] = useState<Set<string>>(new Set());

    const current = { chapter: '', section: '' };

    useEffect(() => {
        // Bug: Sperren des Textfeldes funktioniert noch nicht, wenn nach dem Kurs mit chat Nachricht ein anderer Kurs geöffnet wird.
        const newChatMessage = nodesData.find(
            (node) => node.type === 'chat' && !processedChatMessages.has(String(node.id))
        );
        if (newChatMessage) {
            setTypingAllowed(true);
            setProcessedChatMessages((prev) => new Set(prev).add(String(newChatMessage.id)));
        }
        if (newChatMessage){
            setTypingAllowed(false);
        };
    }, [nodesData]);

    const formatBoldText = (text: string) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); 
    };
  
    // Handle user input and reorder messages including user input and api response
    const handleUserInput = async (userInput: string) => {

        const newUserMessage = {
            content: userInput,
            type: 'userInput',
            isLeft: false,
        } as UserInputInterface;
        setNodesData((prevMessages) => {
            const lastChatMessageIndex = prevMessages.findIndex((msg) => msg.type === 'chat');
            if (lastChatMessageIndex === -1) return prevMessages;
    
            const updatedMessages = [
                ...prevMessages.slice(0, lastChatMessageIndex + 1),
                newUserMessage,
                ...prevMessages.slice(lastChatMessageIndex + 1),
            ];
            return updatedMessages;
        });
    
        console.log("User Message:", newUserMessage);
    
        const lastChatMessage = nodesData.find((node) => node.type === 'chat');
        if (!lastChatMessage) return;
    
        const requestData = {
            context: lastChatMessage.content,
            prompt: lastChatMessage.prompt || '',
            userInput: userInput,
        };
    
        try {

            const response = await fetchChatGPTResponse(requestData.context, requestData.prompt, requestData.userInput);
            const formattedResponse = formatBoldText(response)
            const newBotMessage = {
                content: formattedResponse,
                type: 'response',
                isLeft: true,
            } as ChatResponseInterface;

            setNodesData((prevMessages) => {
                const lastUserMessageIndex = prevMessages.findIndex((msg) => msg === newUserMessage);
                const updatedMessages = [
                    ...prevMessages.slice(0, lastUserMessageIndex + 1),
                    newBotMessage,
                    ...prevMessages.slice(lastUserMessageIndex + 1),
                ];

                //const nextCourseMessage = { content: "Nächste Kurs-Nachricht", type: "io", isLeft: true }; // Beispiel für die nächste Nachricht
                const finalMessages = [
                    ...updatedMessages.slice(0, lastUserMessageIndex + 2),
                    //nextCourseMessage,
                    ...updatedMessages.slice(lastUserMessageIndex + 2),
                ];    
                console.log(finalMessages)
                return finalMessages;
            });
        } catch (error) {
            console.error('Fehler beim Aufruf der API:', error);
        }
    

        setTypingAllowed(false);
    };
    
    // Parsing JSON-Object for multiple choice components
    function parseContent(node: ParagraphInterface) {
        console.log(node);
        if (node.type === 'mc' || node.type === 'io' && node.content.startsWith('%7B%0A%20%20%22type%22%3A%20%22mc')) {
            try {
                const encodedString = node.content;
                const decodedString = decodeURIComponent(encodedString);
                const parsedContent = JSON.parse(decodedString);
                return { ...node, content: parsedContent };
            } catch (error) {
                console.error("Fehler beim Parsen des Inhalts: ", error);
            }
        }
        if (node.type === 'chat') {
            try {
                const encodedStringContent = node.content;
                const decodedStringContent = decodeURIComponent(encodedStringContent);
                
                const encodedStringPrompt = node.prompt;
                const decodedStringPrompt = decodeURIComponent(encodedStringPrompt);
                console.log(decodedStringContent);
                return { ...node, content: decodedStringContent, prompt: decodedStringPrompt };
            } catch (error) {
                console.error("Fehler beim Parsen des Inhalts: ", error);
            }
            return node;
        }

        return node;
    }
    

    const getChatData = async (course_id: string) => {
        const chaptersBody = new URLSearchParams();
        chaptersBody.append('userToken', token || '');
        chaptersBody.append('option', course_id);
        const { result: chapters } = await POST('getChapters', chaptersBody);
        if (chapters) {
            const progressResult = await Promise.all(
                chapters.map(async (chapter: any) => {
                    const progressBody = new URLSearchParams();
                    progressBody.append('userToken', token || '');
                    progressBody.append('option', chapter.id.toString());
                    progressBody.append('content', '2');
                    const { result: progress } = await POST('getUserProgress', progressBody);
                    return parseInt(progress);
                })
            );

            for (var i = 0; i < chapters.length; i++) {
                if (progressResult[i] === 0 || progressResult[i] === 1) {
                    current.chapter = chapters[i].id;
                    const sectionsBody = new URLSearchParams();
                    sectionsBody.append('option', current.chapter);
                    const { result: sections } = await POST('getSections', sectionsBody);

                    if (sections) {
                        const sectionsProgressResult = await Promise.all(
                            sections.map(async (section: any) => {
                                const progressBody = new URLSearchParams();
                                progressBody.append('userToken', token || '');
                                progressBody.append('option', section.id.toString());
                                progressBody.append('content', '3');
                                const { result: progress } = await POST('getUserProgress', progressBody);
                                return parseInt(progress);
                            })
                        );

                        for (let j = 0; j < sections.length; j++) {
                            if (sectionsProgressResult[j] === 0 || sectionsProgressResult[j] === 1) {
                                current.section = sections[j].id;
                                setChapter({ id: chapters[i].id, title: chapters[i].title + ' - ' + sections[j].title, num: chapters[i].ordering, isBookmarked: chapters[i].bookmark === '1' });
                                break;
                            }
                        }
                    }
                    break;
                }
            }
            //Bugfix current section undefined  / history is null former: if(current.section.type === undefined){}
            if (!current.section){
                const sectionsBody = new URLSearchParams();
                sectionsBody.append('option', current.chapter);
                const { result: sections } = await POST('getSections', sectionsBody);
                setChapter({id: sections[0].chapter_id, title: sections[0].title+ ' - ' + sections[0].title, num: chapters[0].ordering, isBookmarked: chapters[0].bookmark === '1' })
                current.section = sections[0].id;
                current.chapter = sections[0].chapter_id;
                    }

            const paragraphsData = await getSectionHistoryParagraphs(current.section);
            console.log("ParagraphsData: ")
            console.log(paragraphsData)
            if (paragraphsData.length === 0) {
                const paragraphsBody = new URLSearchParams();
                paragraphsBody.append('option', current.section);
                const { result: paragraphs } = await POST('getParagraphs', paragraphsBody);
                if (paragraphs) {
                    const start = paragraphs.find((item: ParagraphInterface) => item.type === "start" || item.type === "mc");
                    paragraphsData.push(start);
                    await markParagraph(start.id);
                }
            }
            const parsedNodesData = paragraphsData.map(parseContent);
            setNodesData(parsedNodesData);
            if (nodesData.some((node) => node.type === 'chat')){
                setTypingAllowed(true);
            }
            setIsLoading(false);
        }
    };
    const bookmarkChapter = async (id: string, value: number) => {
        const body = new URLSearchParams();
        if (token)
            body.append('userToken', token);
        body.append('option', id);
        body.append('content', value.toString());
        const response = await POST('setBookmark', body)
        if (response.status === 200) {
            chapter && setChapter({ ...chapter, isBookmarked: value === 1 })
        }
    }

    const clearProgress = async () => {
        const resetBody = new URLSearchParams();
        resetBody.append('userToken', token || '');
        resetBody.append('option', active.id);
        const response = await POST('clearUserProgress', resetBody)
        if (response.status === 200) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            setIsLoading(true)
            setCalledNodes([])
            setNodesData([])
            setChapter(undefined)
            await getChatData(active.id)
            setAlert({ color: 'success', message: 'Course progress cleared!' })
        }
    }

    const getSectionHistoryParagraphs = async (sectionId: string) => {
        const historyBody = new URLSearchParams()
        historyBody.append('userToken', token || '')
        historyBody.append('option', sectionId)
        const historyResp = await POST('getSectionHistory', historyBody)
        let history = historyResp.content
        const paragraphs: ParagraphInterface[] = await Promise.all(
            history.map(async (paragraphId: any) => {
                const body = new URLSearchParams();
                body.append('option', paragraphId.toString());
                const paragraphsResp = await POST('getParagraph', body);
                let paragraph = paragraphsResp.result;
                return paragraph
            })
        )
        return paragraphs
    }

    const getNext = useCallback(async (outputs: string) => {
        setIsLoading(true)
        const [nextParagraphId, delay] = outputs.split('@');

        const body = new URLSearchParams();
        body.append('option', nextParagraphId);

        const startTime = Date.now(); // Record the start time

        const paragraphsResp = await POST('getParagraph', body);

        const endTime = Date.now(); // Record the end time
        const apiResponseTime = endTime - startTime; // Calculate API response time

        // Calculate the remaining delay required to make it total 2 seconds
        const remainingDelay = Math.max(parseInt(delay) * 1000 - apiResponseTime, 0);

        const id = setTimeout(async () => {
            setIsLoading(false)
            let paragraph = paragraphsResp.result;
            setNodesData(nodesData => [...nodesData, paragraph]);
            await markParagraph(paragraph.id)
        }, remainingDelay)
        setTimeoutId(id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const markParagraph = async (paragraphId: string) => {
        const body = new URLSearchParams();
        body.append('userToken', token || '');
        body.append('option', paragraphId);
        await POST('markParagraph', body)
    }

    const handleFeedback = async (sectionId: number, feedback: number) => {
        const body = new URLSearchParams();
        body.append('userToken', token || '');
        body.append('option', sectionId.toString());
        body.append('content', feedback.toString());
        await POST('setSectionFeedback', body)

        updateProgress()
        setIsLoading(true)
        setCalledNodes([])
        setNodesData([])
        setChapter(undefined)
        getChatData(active.id)
    }

    const reportParagraph = async (paragraphId: string, content: string) => {
        const body = new URLSearchParams();
        body.append('userToken', token || '');
        body.append('option', paragraphId.toString());
        body.append('content', content.toString());
        await POST('setParagraphFeedback', body)
    }

    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setIsLoading(true)
        setCalledNodes([])
        setNodesData([])
        setChapter(undefined)
        getChatData(active.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    useEffect(() => {
        if (nodesData && nodesData.length > 0) {
            const last = nodesData[nodesData.length - 1]
            if (last.type !== 'next' && last.outputs && last.outputs.split(',').length === 1) {
                if (!calledNodes.includes(last.id)) {
                    setCalledNodes(calledNodes => [...calledNodes, last.id])
                    getNext(last.outputs);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calledNodes, getNext, nodesData, active]);

    useEffect(() => {
        // Clean up the timeout when the component unmounts
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return (
        <>
            {active &&
                <ChatComponent
                    messagesData={nodesData}
                    date={new Date()}
                    sendDisabled
                    chapter={chapter}
                    bookmarkChapter={bookmarkChapter}
                    clearProgress={clearProgress}
                    handleNext={getNext}
                    isLoading={isLoading}
                    handleFeedback={handleFeedback}
                    reportAllowed
                    reportParagraph={reportParagraph}
                    typingAllowed={typingAllowed}
                    onUserInput={handleUserInput}
                />
            }
        </>
    );
};

export default SkillsChatWrapper;