export function urldecode(encodedString: string): any {
  const decodedString = decodeURIComponent(encodedString);
  return decodedString;
}




export function parseHTMLToJSON(encodedString: string): any {

    const decodedString = decodeURIComponent(encodedString);
    const plainText = decodedString.replace(/<\/?[^>]+(>|$)/g, "");

    const prompt1Match = plainText.match(/Prompt:\s*(.*?)(?=\s*Ich schätze:)/s);
    const prompt2Match = plainText.match(/(Ich schätze:\s*".*?")/s);
    const prompt3Match = plainText.match(/(Bitte bewerte meine Schätzung.*?)(?=\s*Test-Completion von GPT:)/s);
    const responseMatch = plainText.match(/Test-Completion von GPT:\s*(.*)/s);

    const prompt1 = prompt1Match ? prompt1Match[1].trim() : null;
    const prompt2 = prompt2Match ? prompt2Match[1].trim() : null;
    const prompt3 = prompt3Match ? prompt3Match[1].trim() : null;
    const response = responseMatch ? responseMatch[1].trim() : null;

    const jsonObject = {
      type: "GPT",
      Prompt1: prompt1,
      Prompt2: prompt2,
      Prompt3: prompt3,
      Response: response
    };

    return jsonObject;
}
